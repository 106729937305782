@import '../../styling/variables';
.Team {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1280px;
    align-self: center;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .Person {
        flex-basis: 400px;
        padding: 24px;
        img {
            width: 100%;
        }
        h4 {
            margin-top: 16px;
            font-size: 18px;
            letter-spacing: 2px;
            color: $pink;
        }
        p {
            margin-top: 8px;
            text-transform: uppercase;
            // font-weight: 500;
            // font-size: 12px;
        }
    }
}