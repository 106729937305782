.Home {
    display: flex;
    flex-direction: row;
}

.Main {
    flex: 1;
}

section {
    display: flex;
    flex-direction: column;
    // height: 100vh;

    &#information {
        margin-top: 15vh;
        margin-bottom: 15vh;
        // justify-content: center;
        .Block {
            max-width: 720px;
        }
    }

    &#partners {
        margin-top: 15vh;
        margin-bottom: 15vh;
        .Block {
            max-width: 720px;
        }
    }

    &#activities {
        // & .Line {
        //     position: relative;
        //     height: 30vh;
        //     width: 40vw;
        //     bottom: -15vh;
        //     right: 0px;
        // }
    }
    &#lustrum-committee {
        margin-top: 15vh;
        margin-bottom: 15vh;
        h2 {
            text-align: center;
        }
    }

    &#album {
        textarea {
            font-size: 16px;
            height: 6em;
            width: 300px;
            max-width: 100%;
        }
    }
}