@import '../../styling/variables';

.Nav {

    a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        line-height: 2em;
        cursor: pointer;
    }
    &__mobile {
        display: none;
        .Nav__toggle {
            // width: 48px;
            position: fixed;
            right: 16px;
            top: 16px;
            padding: 12px;
            background-color: $blue;
            border-radius: 50%;
            line-height: 0em;
            opacity: .7;
            // display: block;
            img {
                width: 24px;
                // width: 100%;
            }
        }
    }

    @media(min-width: 769px){
        position: sticky;
        top: 0px;
        right: 0px;
        padding: 0px 16px 0px 32px;
        height: 0px;
        flex-basis: 230px;

        height: 100vh;
        align-items: center;
        display: flex;
    }

    @media(max-width: 768px){
        position: fixed;
        top: 0px;
        z-index: 100;

        nav {
            height: 100vh;
            width: 100vw;
            background-color: #ffffffEE;
            padding: 16px;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__mobile {
            display: block;
        }
        &--active {
            nav {
                display: flex;
            }
            .Nav__mobile {
                display: none;
            }
        }
    }

    // @media(max-width: 768px){
    //     position: fixed;
    //     height: auto;
    //     left: 0px;
    //     right: 0px;
    //     background-color: #ffffff;
    //     top: 0px;
    //     z-index: 10;
    //     padding: 16px 16px;

    //     &__mobile {
    //         display: flex;
    //     }
    //     nav {
    //         display: none;
    //     }
    // }
}