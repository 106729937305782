.Hero {
    display: flex;
    height: 100vh;
    width: 100vw;

    justify-content: center;
    align-items: center;
    &__logo {
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        bottom: 15vh;
        // flex: 1;
        flex-basis: 700px;
        padding: 48px;
        margin-bottom: 15vh;

        img {
            width: 100%;
        }

        @media(max-width: 768px){
            padding: 16px 24px;
            margin-bottom: 20vh;
        }
    }
    
    & .Line {
        position: absolute;
        height: 30vh;
        width: 50vw;
        bottom: -15vh;
        right: 0px;
    }
}