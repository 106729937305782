body {
    margin: 0px;
}

.Block {
    padding: 24px;
}

.Button {
    color: #ffffff;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px 16px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    background-color: $dark-green;
    margin-right: 16px;
    white-space: nowrap;
    margin-top: 8px;
    border: 0px;
    // margin-bottom: 4px;

    &--disabled {
        opacity: .4;
        cursor: not-allowed;

        &:hover {
            opacity: .4;
        }
    }

    &--danger {
        background-color: $red;
    }

    &:nth-of-type(2) {
        background-color: $beige;
    }
    &--beige {
        background-color: $beige;
    }

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}