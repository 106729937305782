body {
    font-family: 'Roboto', 'sans-serif';
}

p {
    line-height: 1.6em;
}

a {
    color: $blue;
    &:hover {
        opacity: .7;
    }
}

h2 {
    text-transform: uppercase;
    color: $blue;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0px;
}

h4 {
    text-transform: uppercase;
    color: $blue;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0px;
}