@import '../../styling/_variables.scss';

.Partners {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    margin-top: 32px;
}

.Partner {
    flex-basis: 400px;
    padding: 16px 32px;
    margin-bottom: 16px;
    position: relative;
    opacity: 1;

    img {
        max-width: 100%;
    }

    &:hover {
        opacity: .4;
        // &:before {
        //     content: '';
        //     width: 100%;
        //     height: 100%;
        //     background-color: $pink;
        //     opacity: .4;
        //     position: absolute;
        //     top: 0px;
        //     left: 0px;
        //     display: block;
        // }
    }
}