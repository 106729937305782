@import '../../styling/variables';

.Timeline {
    position: relative;
    margin-top: 32px;
    margin-left: 120px;
    &:before {
        content: ' ';
        width: 18px;
        background-color: $pink;
        display: block;
        bottom: 0px;
        top: 0px;
        position: absolute;
        left: -32px;
        margin-top: 48px;
    }

    .Day {
        display: flex;
        float: left;
        clear: left;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 24px;

        & .Activity {
            flex: 1;
            flex-basis: 300px;
        }
    }

    & .Activity {
        float: left; 
        clear: left; 
        
        padding: 32px;
        margin: 8px 0px 32px 16px;
        position: relative;

        border-radius: 10px;
          
        box-shadow: 0 0px 1px 0 rgba(0,0,0,0.05), 0 5px 12.5px 0 rgba(59,61,63,0.11);

        @media(max-width: 768px){
            padding: 24px;
        }

        h3 {
            margin-top: 0px;
            color: $blue;
            // text-transform: uppercase;
            font-weight: 500;
        }

        &[data-num]:before {
            content: attr(data-num);
            width: 48px;
            height: 48px;
            border-radius: 50%;

            background-color: $pink;
            display: block;
            position: absolute;
            left: -64px;

            text-align: center;
            color: #ffffff;
            font-weight: bold;
            font-size: 24px;
            line-height: 48px;
        }
    }
    &:after {
        content:" ";
        clear:both;
        display:block;
    }

    & .Title {
        float: left; 
        clear: left; 
        
        margin: 32px 16px;
        position: sticky;
        top: 20px;

        border-radius: 10px;

        h4 {
            left: -140px;
            top: 0px;
            text-align: right;
            position: absolute;
            margin: 0px;
            background-color: #ffffff;
            width: 50px;
            padding: 2px 8px;
            // display: block;
        }

        &:before {
            content: '';
            width: 32px;
            height: 32px;
            border-radius: 50%;

            background-color: $pink;
            display: block;
            position: absolute;
            left: -56px;
        }
    }

    & .Line {
        position: absolute;
        height: 30vh;
        width: 20vw;
        bottom: -15vh;
        left: -32px;
    }

    @media(max-width: 768px){
        margin-left: 32px;

        & .Title {
            position: static;
    
            h4 {
                position: static;
            }

            &:before {
                left: -40px;
            }
        }
    }
}